
body{
    font-family: Roboto, sans-serif
}

.banner{
    height: 80px;
    background-color: #455A64;
    padding-right: 24px;
    color: whitesmoke;
    font-size: 28px;
    font-weight: bolder;
    display: flex
}

.titleSection{
    flex: 50%;
    text-align: left;
    display: contents;
}

.authSection{
    flex: 50%;
    text-align: right;
    justify-content: flex-end;
    color: inherit;
}

.accountSection {
    display:flex;
    justify-content:flex-end;
    align-items:center;
}



.footerlogo {
    /*vertical-align: middle;*/
    /*margin-top: auto;*/
    /*margin-bottom: auto;*/
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
}

.footerlogo img {
    /*width: 40px;*/
    /*height: 40px;*/
    /*padding-top: 4px;*/
    /*margin-left: 28px;*/
    /*margin-right: 14px;*/
}

.footer {
    background-color: #455A64;
    height: 70px;
}

.versionSection{
    color: white;
}

.footerLogoSection{
    text-align: center;
    width: 100%;
}

.mainContent{
    min-height: calc(100vh - 150px);
    /*max-width: 1600px;*/
    margin: auto;
    /*border-left-color: darkgrey;*/
    /*border-left-style: double;*/
    /*border-right-color: darkgrey;*/
    /*border-right-style: double;*/
    background-color: white;
}

.fieldsPanel{
    width: 300px;
    border-right: 1px solid #D8D8D8;
    min-height: calc(100vh - 150px);
    /*height: inherit;*/
    padding-top: 60px;
}

.tabsPanel{
    width: calc(100% - 300px);
}

.cardContainer{
    /*text-align: right;*/
    margin-right: 0;
    padding: 4px 8px;
}

.addFieldSection{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: auto;
}

.componentBox{
    border: 1px solid darkgray;
    border-top: 1px solid darkgray;
    line-height: 0;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 20px;
    width:400px;
    border-radius: 8px;
}

.tillageBox{
    border: 1px solid darkgray;
    border-top: 1px solid darkgray;
    line-height: 0;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width:400px;
    border-radius: 8px;
}

.fertilizerBox{
    border: 1px solid darkgray;
    border-top: 1px solid darkgray;
    line-height: 0;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width:400px;
    border-radius: 8px;
}

.boxText{
    background-color: #ffffff;
    height: 35px;
    padding: 0 6px;
    margin: -21px 0 0 20px;
    font-weight: 500;
    font-size: 1.125rem;
    font-variant: all-small-caps;
    width: fit-content;
}

.boxTextWithBeforeAfterTags{
    background-color: #ffffff;
    height: 28px;
    padding: 0 6px;
    margin: -21px 0 0 20px;
    font-weight: 500;
    font-size: 1.125rem;
    font-variant: all-small-caps;
    width: fit-content;
}

.boxTextWithoutTooltip{
    background-color: #ffffff;
    padding: 0 6px;
    margin: 0 20px 0 20px;
    font-weight: 500;
    font-size: 1.125rem;
    font-variant: all-small-caps;
    width: fit-content;
}

.boxTooltip{
    background-color: #ffffff;
    padding: 0;
    margin: 0 1px 0 0;
    font-weight: 500;
    font-size: 20px;
    font-variant: all-small-caps;
}

.boxIconButton{
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.boxIcon {
    width: 0.87em !important;
    height: 0.87em !important;
}

.benchmarkTag{
    background-color: #ffffff;
    padding: 10px 10px;
    margin: 0 20px 0 20px;
    font-weight: 500;
    font-size: 16px;
    font-variant: all-petite-caps;
    text-align: right;
    color: #3f51b5;
}

.currentTag{
    background-color: #ffffff;
    padding: 10px 10px;
    margin: 0 20px 0 20px;
    font-weight: 500;
    font-size: 16px;
    font-variant: all-petite-caps;
    text-align: right;
    color: darkgreen;
}

.componentTextfield{
    margin: 20px 10px;
}

.units {
    vertical-align: middle;
    font-style: italic;
    line-height: 75px;
}

/*fix for firefox to remove arrows for number fields*/
input[type=number] {
    -moz-appearance: textfield;
}

.sectionBanner {
    color: #0c5460;
    background-color: #d4edda;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    border: 1px solid #c3e6cb;
    border-radius: .25rem;
    width: fit-content;
    text-align: left;
}

.alertBanner {
    background-color: #fff3cd;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid #fff3cd;
    border-radius: .25rem;
    width: fit-content;
}

._loading_overlay_content {
    display: flex;
    align-items: center;
    flex-direction: column;
}
