body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
::-webkit-scrollbar {
	width: 15px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
	background-image: linear-gradient(#c1c1c1, #c1c1c1);
	border-radius: 13px;
}
::-webkit-scrollbar-thumb:hover {
	background-image: linear-gradient(#9e9e9e, #9e9e9e);
	border-radius: 13px;
}
